:root {
  --color-primary-dark: #6600cc;
  --color-primary-light: #5b8def;
  --color-secondary-dark: #ac5dd9;
  --color-secondary-light: #dda5e9;
  --color-gray: #787878;
  --color-gray-light: #f2f2f5;
  --color-error: #e71616;

  --z-index--level-one: 100;
  --z-index--level-two: 200;
  --z-index--level-three: 300;
  --z-index--level-four: 400;
  --z-index--level-five: 500;
  --z-index--level-six: 600;
  --z-index--level-ten: 1000;

  --font-family-primary: 'Inter', sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: var(--font-family-primary);
  font-size: 14px;
  color: var(--color-gray);
  -webkit-font-smoothing: antialiased;
  background-color: #ffffff;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
